/* --- IMAGE --- */
@import './../../styles/main.scss';

.image-component {
  @include box-display;
  float: left;
  margin: 90px 0 0;
  padding: 0 0 90px;
  position: relative;
  transition: all 700ms cubic-bezier(0.075, 0.82, 0.165, 1), background-color 0s;
  width: 100%;

  &.fullscreen {
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.8);
    margin: 0;
    position: fixed;
    z-index: 999;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .title-main {
      display: none;
    }

    .image {
      margin: 0 auto;
      height: 100%;
      width: auto;
      display: block;

      img:hover {
        cursor: zoom-out;
      }
    }
  }

  .wrapper {
    text-align: center !important;
  }

  .image {
    @include box-display;
    align-items: center;
    display: inline-flex;
    flex-flow: row wrap;
    justify-content: center;
    margin: 50px 0 0;
    overflow: hidden;
    width: 100%;

    img {
      @include box-display;
      border: none;
      outline: none;
      position: relative;

      &:hover {
        cursor: zoom-in;
      }
    }
  }

  &[data-loaded="true"] {
    padding: 0;
  }
}

/* --- QUERIES --- */
/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .image-component {
    .image {
      margin: 40px 0 0;

      img {
        height: 100%;
        min-height: 400px;
        width: auto;
      }
    }
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .image-component {
    margin: 50px 0 0;

    .image {
      margin: 30px 0 0;

      img {
        min-height: initial;
        width: 100%;
      }
    }
  }
}

/* Custom, iPhone Retina */ 
@media only screen and (max-width : 480px) {
  .image-component {
    .image {
      margin: 20px 0 0;
    }
  }
}