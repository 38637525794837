/* --- NOT FOUND --- */
@import './../../styles/settings/settings.scss';
@import './../../styles/tools/mixins.scss';

.not-found {
  @include box-display;
  float: left;
  position: relative;
  width: 100%;

  &--content,
  &--info,
  &--image {
    @include box-display;
    position: relative;
    vertical-align: top;
  }

  &--content {
    float: left;
    text-align: center;
    width: 100%;
  }

  &--info {
    margin: 70px 40px 0;
    text-align: left;
    width: 370px;

    .description,
    .form,
    .title {
      @include box-display;
      float: left;
      margin: 0;
      position: relative;
      width: 100%;
    }

    .description {
      @include font($font-default, 16px, 500, $black-50);
    }

    .form {
      margin: 25px 0;

      .input-control {
        border: 1px solid $blue-70;
        width: 100%;
      }
    }

    .btn-triangle {
      @include rem('font-size', 18px);
      background-color: $green-200;
      color: $white-900;
      float: left;
      margin: 50px 0 0;
      padding: 10px 25px 12px;
      position: relative;
      text-decoration: none;

      &:after {
        border-left-color: $green-200;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
      }
    }

    .title {
      @include font($font-default, 68px, 100, $blue-900);
      line-height: 1.05em;
      letter-spacing: -0.02em;
      margin: 0 0 30px;
    }
  }

  &--image {
    margin: 90px 40px 0;
    width: 400px;

    img {
      @include box-display;
      border: none;
      height: auto;
      float: left;
      outline: none;
      position: relative;
      width: 100%;
    }
  }
}

/* --- QUEREIS --- */
/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .not-found {
    &--info,
    &--image {
      margin: 20px 0;
    }

    &--info {
      margin-top: 60px;

      .description {
        @include rem('font-size', 14px);
      }

      .title {
        @include rem('font-size', 32px);
      }
    }

    &--image {
      display: none;
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 640px) {
  .not-found {
    &--info,
    &--image {
      padding: 0 30px;
      width: 100%;
    }
  }
}