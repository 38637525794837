.VirtualChapel {
  .Gospel {
    display: flex;
    flex-direction: column;
    color: white;
    width: 100%;

    .gospel-body {
      background-color: rgba(0, 0, 0, 0.6);
      margin: 0 auto;
      padding-left: 24px;
      padding-right: 24px;
      max-width: 66vw;
      //overflow-y: scroll;

      &-text {
        text-align: left;
      }
    }

    hr {
      border-color: rgba(0, 0, 0, 0.2);
    }

    .gospel-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      & .pagination {
        display: flex;

        &:not(.disabled) {
          cursor: pointer;
        }

        &.disabled {
          color: rgb(114, 114, 114);
          cursor: not-allowed;
        }
      }
    }
  }

  @media only screen and (max-width: 768px) {
    .Gospel {
      .gospel-body {
        max-width: unset;

        &-text {
          font-size: 1rem;
        }
      }

      .gospel-header {
        h2 {
          font-size: 1.2rem;
          padding: 8px;
        }
      }
    }
  }
}