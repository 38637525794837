/* --- FORMS --- */
@import './../../styles/main.scss';

.forms {
  @include box-display;
  float: left;
  margin: 70px 0 0;
  position: relative;
  width: 100%;

  &--content,
  &--form {
    @include box-display;
    float: left;
    position: relative;
  }

  &--content {
    background-color: $blue-50;
    padding: 20px;
    max-width: 100%;

    &--error {
      @include box-display;
      background-color: $red-700;
      float: left;
      margin: 15px 0 0;
      padding: 10px 25px;
      position: relative;
      text-align: left;
      width: 100%;

      .text {
        @include box-display;
        @include font($font-default, 14px, 400, $white-900);
        float: left;
        margin: 0;
        line-height: 1em;
      }
    }

    .input-control {
      @include box-display;
      float: left;
      margin: 15px 0;
      outline: none;
      position: relative;
      width: 100%;

      input,
      textarea,
      .file {
        @include box-display;
        @include font($font-default, 18px, normal, $blue-900);
        background-color: $white-900;
        border: 1px solid $blue-300;
        float: left;
        height: 47px;
        line-height: 1em;
        outline: none;
        padding: 10px 22px 12px;
        position: relative;
        width: 100%;

        &[data-empty="false"],
        &:focus {
          & + .label {
            @include rem('font-size', 12px);
            left: 0;
            top: -17px;

            span {
              display: none;
              font-weight: 300;
            }
          }
        }
      }

      textarea {
        height: 200px;
        resize: none;
      }  
        
      .label {
        @include box-display;
        @include font($font-default, 18px, 300, $black-200);
        left: 22px;
        outline: none;
        pointer-events: none;
        position: absolute;
        top: 12px;
        transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
        z-index: 2;

        span {
          @include box-display;
        }
      }

      .file {
        padding: 10px 40px 12px 22px;

        input {
          cursor: pointer;
          height: 100%;
          left: 0;
          opacity: 0;
          position: absolute;
          top: 0;
          width: 100%;
        }

        &-name {
          float: left;
          max-width: 100%;
          overflow: hidden;
          position: relative;
          text-align: left;
          text-overflow: ellipsis;
          top: 2px;
          white-space: nowrap;
          width: 100%;
        }

        .icon {
          @include box-display;
          @include rem('font-size', 18px);
          color: $blue-100;
          right: 15px;
          position: absolute;
          top: 14px;

          &.icon-link {
            background-image: url('/images/icon-link.png');
            height: 15px;
            width: 18px;
          }
        }

        &[data-active="true"] {
          & + label {
            @include rem('font-size', 12px);
            left: 0;
            top: -17px;
          }
        }
      }

      &[data-error="true"] {
        .label {
          color: $red-700;
        }

        input,
        textarea {
          border-color: $red-700;
        }

        .file {
          border: 1px solid $red-700;
    
          & + .label {
            display: none;
          }

          &-name {
            @include font($font-default, 18px, 300, $red-700);
          }
        }
      }

      .select--component {
        .select--content {
          max-height: 225px;
        }

        & + .label {
          display: none;
        }
      }
    }
  }

  &--form {
    float: none;

    .btn-triangle {
      background-color: $blue-70;
      border: none;
      color: $white-900;
      float: right;
      margin: 20px 10px 0 0;
      outline: none;
      position: relative;
      cursor: pointer !important;

      &:after {
        border-left-color: $blue-70;
      }
    }

    &.faleconosoco {
      padding: 0 10px;
      width: 460px;
    }

    & + .forms--success {
      width: 460px;
    }
  }

  &--success {
    @include box-display;
    display: none;
    float: none;
    padding: 0 10px 20px;
    position: relative;
    text-align: center;
    width: 100%;

    .text {
      @include box-display;
      @include font($font-default, 24px, 400, $green-200);
    }
  }

  .wrapper {
    text-align: center;
  }

  &[data-success="true"] {
    .forms {
      &--form {
        display: none;
      }

      &--success {
        display: inline-block;
      }
    }
  }
}