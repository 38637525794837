@import 'styles/components/form/buttons.scss';

.gallery {
  &--controls {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 5;
    padding: 25px 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 66.45%, rgba(0, 0, 0, 0.8) 100%);
    pointer-events: none;

    & .control {
      @extend %btn-clear;
      @include rem("font-size", 23px);

      color: $white-900;
      cursor: pointer;
      padding: 0 24px;
      pointer-events: auto;

      &:disabled {
        color: $black-150;
        cursor: not-allowed;
      }
    }
  }
}
