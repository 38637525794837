.quick-access {
  max-width: 1373px;
  padding-top: 60px;
  margin-right: auto;
  margin-bottom: 96px;
  margin-left: auto;
  clear: both;

  &-title {
    font-family: 'Montserrat';
    font-size: 33px;
    line-height: 44px;
    font-weight: 400;
    text-align: left;
    color: #00315E;
    margin-top: 0;
    margin-bottom: 23px;
  }

  .slick {
    &-slider {
      padding: 0 33px;
    }

    &-slide {
      height: 116px;

      & > div {
        height: 100%;
        background: #E4F5FD;
        margin-right: 6px;
        display: flex;
      }

      &-link {
        text-decoration: none;
        padding-top: 14px;
        flex: 1;

        &-image {
          height: 47px;
          display: flex;
          justify-content: center;

          img {
            height: 40px;
            max-width: 60px;
            display: inline-block;
            object-fit: contain;
          }
        }
      }

      p {
        font-family: 'Montserrat';
        font-size: 16px;
        line-height: 16px;
        font-weight: 600;
        text-align: center;
        color: #0173A4;
        padding-right: 5px;
        padding-left: 5px;
        margin-top: 8px;
        margin-bottom: 0;
      }
    }

    &-prev,
    &-next {
      width: 20px;
      height: 35px;
      background: url("/images/icons/slider-arrow.svg") no-repeat center;  
      background-size: cover;
      opacity: 1;
      transition: opacity ease-in-out 0.2s;

      &:before {
        content: "";
      }

      &.slick-disabled {
        opacity: 0.4;
        cursor: initial;
      }
    }

    &-prev {
      left: 0;
    }

    &-next {
      right: 0;
      margin-top: -10px;
      transform: rotate(180deg);
    }
  }
}

@media (max-width: 1400px){
  .quick-access { 
    padding-right: 2.5%;
    padding-left: 2.5%;
  }
}