.content {
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 8px;
  position: relative;

  h1 {
    margin-top: 0;
  }

  .loading {
    z-index: 999;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    overflow: none;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);

    & span {
      margin: 0 auto;
    }
  }
}