.VirtualChapel {
  $btn-primary-color:#72a0dce2;
  $btn-accent-color: #4e85ce;

  .btn {
    background-color: #72a0dce2;
    border: none;
    color: white;
    outline: none;
    cursor: pointer;
    font-size: 0.9375rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    line-height: 1em;
    padding: 8px 25px 9px;
    transition: .2s all ease-in-out;

    &:hover {
      background-color: #4e85ce;
    }

    &.btn-triangle-right {
      &:hover::after {
        border-left-color: $btn-accent-color;
      }

      &::after {
        content: '';
        border-top: 16px solid transparent;
        border-bottom: 16px solid transparent;
        border-left: 10px solid #72a0dce2;
        right: -10px;
        top: 0;
        position: absolute;
        transition: .2s all ease-in-out;
      }
    }

    &.btn-triangle-left {
      &:hover::after {
        border-right-color: $btn-accent-color;
      }

      &::after {
        content: '';
        border-top: 16px solid transparent;
        border-bottom: 16px solid transparent;
        border-right: 10px solid #72a0dce2;
        left: -10px;
        top: 0;
        position: absolute;
        transition: .2s all ease-in-out;
      }
    }
  }

  .Candles {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    padding-left: 1em;
    padding-right: 1em;

    span {
      color: white;
    }

    .candle-form {
      margin-top: 1.5rem;
      max-height: 50vh;

      .forms--content {
        margin: 0 auto;
      }
    }

    .lit-candles {
      overflow-y: scroll;
      max-height: 55vh;
      // flex: 0.55;
      flex-grow: 0;
      flex-basis: 50%;

      ul {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        list-style-type: none;
        gap: 20px;
        padding: 0;

        .pagination {
          grid-column: span 3;

          button {
            margin: 0 auto;
          }
        }
      }

      li {
        padding: 8px;
        background-color: rgba(0, 0, 0, 0.6);
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        transition: .35s all ease-in-out;
        font-size: .85em;

        &:hover {
          cursor: pointer;
          background-color: rgba(0, 0, 0, 0.85);
        }
      }
    }

    .candle-icon {
      height: 3rem;
      display: block;
      margin: 0 auto;
      margin-bottom: 8px;
    }
  }

  @media only screen and (max-width : 768px) {
    .Candles {
      flex-direction: column;
      padding-left: 0;
      padding-right: 0;

      .candle-form {
        width: 100%;
        margin: 0 auto;
        max-height: none;
        overflow-y: unset;
      }

      .lit-candles {
        overflow-y: unset;
        flex: none;
        max-height: none;

        ul {
          padding: 0;
          grid-template-columns: 1fr 1fr;
        }
      }
    }
  }
}