/* --- CIRCULARS ITEM --- */
@import './../../../styles/settings/settings.scss';
@import './../../../styles/tools/mixins.scss';

@import './../../../styles/components/animations.scss';

.circulars--item {
  @include box-display;
  animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  background-color: $white-500;
  float: left;
  margin: 10px 0;
  position: relative;
  width: 100%;

  .data,
  .download,
  .info {
    @include box-display;
    float: left;
    position: relative;
  }

  .data {
    border: none;
    border-left: 10px solid $blue-70;
    padding: 0 0 10px;
    text-align: center;
    width: 165px;

    .date {
      @include box-display;
      float: left;
      position: relative;
      width: 100%;

      .day,
      .month,
      .year {
        @include box-display;
        float: left;
        line-height: 1em;
        position: relative;
        width: 100%;
        vertical-align: top;
      }

      .day {
        @include font($font-default, 64px, 300, $blue-900);
      }
      
      .month {
        @include font($font-default, 36px, 300, $blue-900);
      }

      .year {
        @include font($font-default, 27px, 300, $blue-900);
      }
    }
  }

  .download {
    align-items: center;
    display: inline-flex;
    justify-content: flex-start;
    flex-flow: row wrap;
    height: 100%;
    right: 0;
    position: absolute;
    text-decoration: none;
    top: 0;
    width: 390px;

    .bg,
    .icon,
    .info,
    .text {
      @include box-display;
      float: left;
      position: relative;
      z-index: 1;
    }

    .icon {
      @include rem('font-size', 50px);
      color: $blue-70;
      margin: 0 0 0 90px;
      width: auto;
    }

    .info {
      align-items: center;
      display: inline-flex;
      justify-content: flex-start;
      flex-flow: row wrap;
      width: calc(100% - 150px);

      .size,
      .type {
        @include box-display;
        @include font($font-default, 18px, 300, $blue-70);
        float: left;
        position: relative;
      }

      .type {
        border: none;
        border-left: 1px solid $blue-70;
        margin-left: 10px;
        padding-left: 10px;
        text-transform: uppercase;
      }

      .text {
        @include font($font-default, 18px, 400, $blue-900);
        margin: 0 0 5px;
        width: 100%;
      }
    }

    .bg {
      left: 0;
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 0;
    }
  }

  .info {
    align-content: center;
    display: inline-flex;
    flex-flow: row wrap;
    justify-content: center;
    height: 170px;
    padding: 30px;
    text-decoration: none;
    width: calc(100% - (165px + 390px));

    .description,
    .title {
      @include box-display;
      float: left;
      margin: 0;
      line-height: 1.2em;
      position: relative;
      width: 100%;
    }

    .description {
      @include font($font-default, 18px, 500, rgba(0, 0, 0, 0.5));
    }

    .title {
      @include font($font-default, 20px, 600, $blue-900);
      margin: 0 0 5px;
    }
  }

  &[data-type="fundamental_1"] {
    .data {
      border-left-color: $yellow-600;
    }
  }

  &[data-type="fundamental_2"] {
    .data {
      border-left-color: $green-200;
    }
  }

  &[data-type="ensino_medio"] {
    .data {
      border-left-color: $blue-400;
    }
  }
}

/* --- QUERIES --- */
/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .circulars--item {
    .data {
      width: 120px;

      .date {
        .day {
          @include rem('font-size', 32px);
        }

        .month {
          @include rem('font-size', 25px);
        }

        .year {
          @include rem('font-size', 18px);
        }
      }
    }

    .download {
      justify-content: flex-end;
      width: 260px;

      .icon {
        @include rem('font-size', 35px);
        margin-left: 30px;
      }

      .info {
        float: right;
        padding: 20px 10px 20px 25px;
        width: calc(100% - 100px);

        .size,
        .text,
        .type {
          @include rem('font-size', 14px);
        }
      }
    }

    .info {
      height: 115px;
      padding: 20px;
      width: calc(100% - (120px + 260px));

      .description,
      .title {
        @include rem('font-size', 14px);
      }
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 640px) {
  .circulars--item {
    .download {
      width: 110px;

      .icon {
        margin: 0 30px;
      }

      .info {
        display: none;
      }
    }

    .info {
      width: calc(100% - (120px + 110px));
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 480px) {
  .circulars--item {
     margin: 2px 0;
     display: flex;

    .data {
      align-items: center;
      align-content: center;
      display: inline-flex;
      justify-content: center;
      flex-flow: row wrap;
      padding: 0;
      //position: absolute;
      //width: 80px;
      //left: 0;
      width: auto;
      flex: 0;
      padding: 0px 18px 0px 18px;
      
      .date {
        margin: 10px 0;
        
        .day,
        .month,
        .year {
          margin: 2px 0;
        }
        
        .day {
          @include rem('font-size', 29px);
        }
        
        .month {
          @include rem('font-size', 22px);
        }
        
        .year {
          @include rem('font-size', 15px);
        }
      }
    }
    
    .download {
      justify-content: center;
      width: 60px;
      
      .icon {
        @include rem('font-size', 25px);
        margin: 0;
        left: 5px;
        float: none;
        width: auto;
      }
    }
    
    .info {
      //height: auto;
      //left: 80px;
      padding: 20px 5px;
      //width: calc(100% - (80px + 60px));
      flex: 1;
      margin-right: 60px;
    }
  }
}