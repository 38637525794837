/* --- CALENDAR DAY --- */
@import './../../../styles/settings/settings.scss';
@import './../../../styles/tools/mixins.scss';

.calendar--day {
  @include box-display;
  background-color: $white-500;
  display: inline-flex;
  float: left;
  height: 65px;
  max-width: 100%;
  margin: 1px;
  position: relative;
  width: calc((100% / 7) - 2px);

  &--events {
    @include box-display;
    float: left;
    list-style: none;
    margin: 0;
    padding: 10px 0 0;
    text-align: center;
    width: 100%;

    &--item {
      @include box-display;
      float: left;
      height: 7px;
      margin: 2px 0;
      position: relative;
      width: 100%;
      vertical-align: top;

      .block {
        @include box-display;
        border-radius: 4px;
        cursor: pointer;
        height: 7px;
        position: relative;
        width: 20px;
        vertical-align: top;
        z-index: 2;
      }

      .content {
        @include box-display;
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2);
        bottom: calc(100% + 15px);
        background-color: $blue-50;
        border: 1px solid $white-900;
        display: none;
        left: 50%;
        margin: 0;
        position: absolute;
        transform: translate(-50%, 0);
        vertical-align: top;
        z-index: 9;
        width: 165px;

        .description {
          @include box-display;
          @include font($font-default, 14px, 400, $blue-900);
          cursor: pointer;
          line-height: 1.5em;
          padding: 15px 25px;
          position: relative;
          text-align: center;
        }

        &:before,
        &:after {
          @include box-display;
          content: '';
          height: 0;
          left: 50%;
          position: absolute;
          transform: translate(-50%, 0);
          width: 0;
        }

        &:before {
          bottom: -14px;
          border-left: 16px solid transparent;
          border-right: 16px solid transparent;
          border-top: 13px solid $white-900;
        }

        &:after {
          bottom: -12px;
          border-left: 15px solid transparent;
          border-right: 15px solid transparent;
          border-top: 12px solid $blue-50;
        }
      }

      &:hover {
        .content {
          display: inline-block;
        }

        .description {
          display: inline-block;
        }
      }

      .text {
        @include box-display;
        bottom: 0;
        left: 50%;
        position: absolute;
        transform: translate(-50%, 0);

        &:empty {
          display: none;
        }
      }
    }

    &:empty {
      display: none;
    }
  }

  .day {
    @include box-display;
    @include font($font-default, 18px, 400, $blue-900);
    bottom: 8px;
    left: 0;
    line-height: 1.5em;
    margin: 0;
    position: absolute;
    text-align: center;
    width: 100%;
  }

  &[data-type="day-out"] {
    border: 1px solid $white-500;
    background-color: $white-900;

    .calendar--day {
      &--events {
        display: none;
      }
    }
    
    .day {
      display: none;
    }
  }

  &[data-end="true"] {
    .day {
      color: $black-200;
    }
  }
}