/* --- CALENDAR YEAR --- */
@import './../../../styles/settings/settings.scss';
@import './../../../styles/tools/mixins.scss';

.calendar--year {
  @include box-display;
  float: left;
  position: relative;
  width: 100%;

  &--content {
    @include box-display;
    align-items: flex-start;
    display: inline-flex;
    justify-content: flex-start;
    float: left;
    flex-flow: row wrap;
    position: relative;
    width: 100%;
  }
}