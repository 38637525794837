/* --- VIDEO --- */
@import '../../styles/settings/settings.scss';
@import '../../styles/tools/mixins.scss';

.video {
  @include box-display;
  float: left;
  margin: 55px 0 0;
  position: relative;
  width: 100%;
  vertical-align: top;

  .wrapper {
    text-align: center;

    > div {
      @include box-display;
    }
  }
}

/* --- QUERIES --- */
@media only screen and (max-width: 768px) {
  .video {
    margin: 40px 0 0;
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 640px) {
  .video {
    .wrapper {
      > div {
        height: auto !important;
        width: 100% !important;

        > div {
          height: auto !important;
          position: relative;
          padding-bottom: 56.25%;
          padding-top: 30px; height: 0; overflow: hidden;
          width: auto !important;

          iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
          }
        }
        
      }
    }
  }
}