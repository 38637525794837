/* --- CALENDAR MONTH --- */
@import './../../../styles/settings/settings.scss';
@import './../../../styles/tools/mixins.scss';

.calendar--month {
  @include box-display;
  float: left;
  margin: 25px 0;
  padding: 0 20px;
  position: relative;
  width: calc(100% / 3);

  .title {
    @include box-display;
    @include font($font-default, 18px, 300, $blue-900);
    float: left;
    line-height: 1.7em;
    margin: 0 0 25px 0;
    position: relative;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
  }
}

/* --- QUERIES --- */
/* Medium Devices, Desktops */
@media only screen and (max-width : #{$page-width}) {
  .calendar--month {
    width: calc(100% / 2);
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 640px) {
  .calendar--month {
    width: 100%;
  }
}