/* --- LIST COLLAPSE --- */
@import './../../../styles/settings/settings.scss';
@import './../../../styles/tools/mixins.scss';

@import './../../../styles/components/form/buttons.scss';

.list-collapse {
  @include box-display;
  float: left;
  position: relative;
  width: 100%;

  &--item,
  &--list,
  &--title {
    @include box-display;
    position: relative;
    text-align: left;
  }

  &--item {
    float: left;
    margin: 0 0 5px 0;
    position: relative;
    width: 100%;

    .body,
    .header {
      @include box-display;
      float: left;
      position: relative;
      width: 100%;
    }

    .body {
      max-height: 0;
      padding: 0 0 10px;
      transition:max-height 0.3s ease-out;
      overflow: hidden;

      p {
        @include box-display;
        @include font($font-default, 18px, 300, $black-200);
        float: left;
        line-height: 1.3em;
        margin: 10px 0;
        position: relative;
        width: 100%;
      }
    }

    .header {
      align-items: center;
      background-color: $white-500;
      cursor: pointer;
      display: inline-flex;
      height: 70px;
      justify-content: flex-start;
      flex-flow: row wrap;
      margin: 0;

      .btn,
      .title {
        @include box-display;
        position: relative;
      }

      .btn {
        @extend %btn-clear;
        left: 25px;
        padding: 0;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);

        .icon {
          @include box-display;
          display: none;
          color: $blue-350;
          float: left;
          position: relative;

          &.icon-plus {
            @include rem('font-size', 20px);
            display: inline-block;
          }

          &.icon-minus {
            @include rem('font-size', 20px);
          }
        }
      }

      .title {
        @include font($font-default, 18px, 500, $blue-900);
        line-height: 1em;
        padding: 0 0 0 70px;
        width: 100%;
      }
    }

    &[data-open="true"] {
      .body {
        max-height: 1000px;
      }

      .header {
        background-color: $blue-50;

        .btn {
          .icon {
            &.icon-plus {
              display: none;
            }

            &.icon-minus {
              display: inline-block;
            }
          }
        }
      }
    }
  }

  &--list {
    list-style: none;
    max-width: 700px;
    padding: 0;
    width: 100%;
  }

  .wrapper {
    text-align: center;

    .title-main {
      &:empty {
        &:before {
          display: none;
        }
      }
    }
  }
}

/* --- QUERIES --- */
/* Small Devices, Tablets */
@media only screen and (max-width : 768px) {
  .list-collapse {
    .wrapper {
      padding: 0 40px;
    }
  }
}

/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 640px) {
  .list-collapse {
    .wrapper {
      padding: 0 20px;
    }
  }
}