.fullscreen-gallery {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.9);
  z-index: 999;
  display: flex;
  flex-direction: column;

  &--close {
    font-size: 2rem;
    color: rgba(255, 255, 255, 0.75);
    display: block;
    padding: 8px;
    transform: rotate(45deg);
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    right: 0;
  }

  &--images {
    flex: 1;
    display: flex;
    align-items: center;

    .image {
      height: auto;
      margin: 0 auto;
    }

    img {
      width: 100%;
    }
  }

  &--thumbnails {
    list-style-type: none;
    overflow-x: auto;
    align-items: center;

    &--item {
      height: 10vh;
      //width: 30px;
      display: inline-block;
      border: 3px ridge rgb(53, 53, 53);
      opacity: 0.4;

      img {
        height: 100%;
        width: 100%;
      }

      &.active {
        opacity: 1;
      }
    }
  }
}