/* --- NEWS ITEM --- */
@import './../../../styles/settings/settings.scss';
@import './../../../styles/tools/mixins.scss';

@import './../../../styles/components/animations.scss';

.news--item {
  width: 100%;
  min-height: 400px;
  animation: fade-in-bottom 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
  background-color: #FFFFFF;
  float: none;
  max-width: 448px;
  margin: 10px 0;
  position: relative;
  text-align: left;

  .item {
    &-link {
        display: flex;
        flex-direction: column;
    }

    &-image {
        max-height: 203px;
    
        img {
            width: 100%;
            height: 100%;
        }  
    }
  }

  .data {
    background-color: $yellow-600;
    border: none;
    text-align: left;
    width: 100%;

    .date {
        width: 100%;
        @include font($font-default, 16px, 700, $blue-900);
        padding-left: 10px;
        margin: 5.5px 0;
        float: left;
        position: relative;  
        display: flex;
        justify-content: flex-start;

      .day,
      .month,
      .year {
        @include box-display;
        float: left;
        line-height: 1em;
        position: relative;
        width: fit-content;
        vertical-align: top;
      }

      .day,
      .month {
        &::after {
            content: '-';
        }
      }
    }
  }

  .info {
    width: 100%;

    .description,
    .title {
      @include box-display;
      float: left;
      padding: 16px 14px 30px 14px;
      margin: 0;
      line-height: 1.2em;
      position: relative;
      text-decoration: none;
      width: 100%;
    }

    .description {
      @include font($font-default, 18px, 500, rgba(0, 0, 0, 0.5));
    }

    .title {
      @include font($font-default, 22px, 500, $blue-900);
      margin: 0 0 5px;
    }
  }
}

/* --- QUERIES --- */
/* Extra Small Devices, Phones */ 
@media only screen and (max-width : 640px) {
  .news--item {
    max-width: 310px;
    min-height: 250px;

    .item {
      &-image {
        max-height: 152px
      }
    }

    .info {
      .title {
        @include font($font-default, 18px, 500, $blue-900);
        margin: 0 0 5px;
      }
    }
  }
}