/* --- SOCIAL NETWORKS --- */
@import './../../styles/settings/settings.scss';
@import './../../styles/tools/mixins.scss';

.social-networks {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 1380px;
  width: 100%;
  height: 361px;
  margin: 96px auto 260px;
  position: relative;
  font-family: "Montserrat", sans-serif;

  &-social-media {
    width: 344px;
    height: 100%;
    overflow-y: hidden;

    h2 {
      text-align: left;
      font-size: 33px;
      line-height: 44px;
      font-weight: 400;
      color: #00315E;
      margin: 0 0 19px 0;
    }

    &-links {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 298px;
      background-color: $white-500;
      text-align: left;

      span {
        font-size: 16px;
        line-height: 21px;
        font-weight: 500;
        color: #787878;
        padding: 33px 20px 20px 27px;
      }

      a {
        display: flex;
        align-items: center;
        width: fit-content;
        font-size: 18px;
        line-height: 24px;
        font-weight: 700;
        color: #0173A4;
        margin: 0 20px 19px 27px;
        text-decoration: none;
        transition: all .3s ease-in-out;

        &:hover {
          color: #00315E;
        }

        img {
          width: 28px;
          height: 28px;
          margin-right: 10px;
        }
      }
    }
  }
}

/* --- QUERIES --- */
@media only screen and (max-width: 1400px) {
  .social-networks {
    width: 95%;
    height: auto;

    .circulars {
      width: 97%;
      height: auto;
      
      &-item {
        width: fit-content;
      }
    }
  }
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 959px) {
  .social-networks {
    flex-direction: column;
    justify-content: unset;
    max-width: 336px;
    height: auto;
  
    &-social-media {
      width: 100%;
      margin-top: 50px;
  
      h2 {
        font-size: 30px;
        line-height: 40px;
      }
  
      &-links {
        height: 263px;
  
        span {
          padding: 33px 13px 20px;
        }
  
        a {
          font-size: 16px;
          line-height: 21px;
          margin: 0 20px 19px 13px;
  
          img {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
}