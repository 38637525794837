/* --- BUTTONS --- */
@import './../../settings/colors.scss';
@import "./../../settings/fonts.scss";
@import "./../../tools/mixins.scss";

%btn-clear {
  @include box-display;
  background-color: transparent;
  border: none;
  box-shadow: none;
  outline: none;
}

.btn {
  @extend %btn-clear;
  position: relative;

  &-triangle {
    @include font($font-default, 15px, 400, $blue-300);
    background-color: $white-900;
    line-height: 1em;
    padding: 8px 25px 9px;

    &:after {
      @include box-display;
      content: '';
      border-top: 16px solid transparent;
      border-bottom: 16px solid transparent;
      border-left: 10px solid $white-900;
      height: 0;
      right: -10px;
      position: absolute;
      top: 0;
      width: 0;
    }
  }
}

.btn-default--link {
  @extend %btn-clear;
  @include font($font-default, 15px, 600, $blue-300);
  background-color: $white-900;
  line-height: 1em;
  padding: 17px 20px 19px;
  position: relative;
  text-decoration: none;
}

/* --- QUERIES --- */
/* Custom, iPhone Retina */ 
@media only screen and (max-width : 480px) {
  .btn-default--link {
    @include rem('font-size', 14px);
    padding: 14px 15px 15px;
  }
}