/* --- CIRCULARS --- */
@import './../../../styles/settings/settings.scss';
@import './../../../styles/tools/mixins.scss';

.social-networks {
  &--circulars {
    max-width: 1019px;
    width: 100%;
    height: 100%;
    position: relative;

    .title {
      text-align: left;
      font-size: 33px;
      line-height: 44px;
      font-weight: 400;
      color: #00315E;
      margin: 0 0 19px 0;
    }

    .circulars {
      background-color: $white-500;
      list-style: none;
      height: 298px;
      display: flex;
      flex-wrap: wrap;
      padding: 33px 30px 43px 33px;
      gap: 34px;
      margin: 0;
      position: relative;

      &:before {
        content: url("/images/Social-network-Rectangle.svg");
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 0;
      }

      &--item {
        display: flex;
        flex-direction: column;
        text-align: left;
        width: 458px;
        min-height: 73px;
        max-height: 94px;
        padding: 0 20px;
        margin: 0;
        position: relative;
        background-color: transparent !important;
        overflow-y: hidden;

        &[data-type="fundamental_1"] {
          border-left: 6px solid $yellow-600;
        }

        &[data-type="fundamental_2"] {
          border-left: 6px solid $green-200;
        }

        &[data-type="ensino_medio"] {
          border-left: 6px solid $blue-400;
        }

        .info {
          @include box-display;
          height: auto;
          margin: 0;
          padding: 0;
          position: relative;
          width: 100%;

          .description {
            @include font($font-default, 16px, 500, #787878);
            line-height: 21px;
            width: 100%;
          }

          .date {
            @include font($font-default, 14px, 700, #787878);
            line-height: 19px;
          }
        }

        .link {
          display: flex;
          align-items: center;
          @include font($font-default, 14px, 700, #4AC8F5);
          font-family: "Montserrat", sans-serif !important;
          width: fit-content;
          margin-top: 8px;
          text-decoration: none;
          transition: all .3s ease-in-out;

          &:hover {
            color: #00315E;

            &::before {
              color: #4AC8F5;
            }
          }

          &::before {
            font-family: "icomoon";
            color: #4AC8F5;
            font-size: 22px;
            margin-right: 8px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width : 959px) {
  .social-networks {
    &--circulars {
      max-width: 336px;
  
      .title {
        font-size: 30px;
        line-height: 40px;
      }
  
      .circulars {
        height: auto;
        display: flex;
        flex-direction: column;
        flex-wrap: unset;
        padding: 33px 12px;
        gap: 34px;
  
        &:before {
          content: url("/images/Social-network-Rectangle-mobile.svg");
          top: unset;
          bottom: 0;
          width: 336px;
          height: 298px;
        }
  
        &--item {
          width: 310px;
          max-height: 116px;
  
          .info {  
            .description {
              @include font($font-default, 16px, 500, #787878);
              line-height: 21px;
              width: 100%;
            }
  
            .date {
              @include font($font-default, 14px, 700, #787878);
              line-height: 19px;
            }
          }
        }
      }
    }
  }
}