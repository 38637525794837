/* --- CALENDAR MONTH DAYS --- */
@import './../../../../styles/settings/settings.scss';
@import './../../../../styles/tools/mixins.scss';

.calendar--month{
  &--days,
  &--rows {
    @include box-display;
    float: left;
    position: relative;
    width: 100%;
  }

  &--rows {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    flex-flow: row wrap;
  }
}