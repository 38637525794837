/* --- ANIMATIONS --- */
/**
 * ----------------------------------------
 * animation fade-in-bottom
 * ----------------------------------------
 */
 @keyframes fade-in-bottom {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes prevIn {
  0% {
    left: -33.333333%;
  }
  100% {
    left: 0%;
  }
}

@keyframes prevOut {
  0% {
    left: 66.666666%;
  }
  100% {
    left: 100%;
  }
}

@keyframes prevNext {
  0% {
    left: 0;
  }
  100% {
    left: 33.333333%;
  }
}

@keyframes prevNextNext {
  0% {
    left: 33.333333%;
  }
  100% {
    left: 66.666666%;
  }
}

@keyframes nextIn {
  0% {
    left: 33.333333%;
  }
  100% {
    left: 0%;
  }
}

@keyframes nextOut {
  0% {
    left: 0%;
  }
  100% {
    left: -33.333333%;
  }
}

@keyframes nextNext {
  0% {
    left: 66.666666%;
  }
  100% {
    left: 33.333333%;
  }
}

@keyframes nextNextNext {
  0% {
    left: 100%;
  }
  100% {
    left: 66.666666%;
  }
}

/* --- MOBILE --- */
@keyframes nextInMobile {
  0% {
    left: 100%;
  }
  100% {
    left: 0%;
  }
}

@keyframes nextOutMobile {
  0% {
    left: 0%;
  }
  100% {
    left: -100%;
  }
}

@keyframes prevInMobile {
  0% {
    left: -100%;
  }
  100% {
    left: 0%;
  }
}

@keyframes prevOutMobile {
  0% {
    left: 0%;
  }
  100% {
    left: 100%;
  }
}

/* ----------------------------------------------
 * Generated by Animista on 2019-6-25 11:27:35
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

 @keyframes slide-in-right {
  0% {
    transform: translateX(1000px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slide-in-left {
  0% {
    transform: translateX(-1000px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}