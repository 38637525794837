/* --- BOX BOXING --- */
@mixin box-display {
  box-sizing: border-box;
  display: inline-block;
}

/* --- FONT --- */
@mixin font($font: $font-family, $size: 12px, $weight: 400, $color: $black-700) {
  @include rem('font-size', $size);
  color: $color;
  font-family: $font;
  font-weight: $weight;
}

/* --- REM --- */
@mixin rem($property, $px_values) {
  $baseline_rem: calc($base-font-size / 1rem);
  #{$property}: $px_values;

  // If there is only one (numeric) value, return the property/value line for it.
  @if type-of($px_values) == 'number' {
    #{$property}: calc($px_values / $baseline_rem);
  }
  @else {
    $rem_values: ();

    @each $value in $px_values {
      @if $value == 0 {
        $rem_values: append($rem_values, $value);
      } @else {
        $rem_values: append($rem_values, ($value / $baseline_rem));
      }
    }

    // Return the property and its list of converted values
    #{$property}: $rem_values;
  }
}

/* --- ANIMATIONS --- */