@import 'styles/components/form/buttons.scss';

.gallery {
  @include box-display;
  position: relative;
  // width: 100%;
  width: 700px;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  &--item {
    position: relative;
    height: 500px;
    transition: 0.4s opacity ease-in;

    &[data-current="false"] {
      opacity: 0;
      height: 0;

      & .image {
        display: none;
      }
    }

    .image {
      @include box-display;
      position: relative;
      height: 100%;
      width: 100%;

      img {
        @include box-display;
        outline: none;
        position: relative;
        height: 100%;
        width: 100%;
        max-width: 100%;
        overflow: hidden;
      }
    }

    &-info {
      flex: 1;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      color: white;

      .description {
        font-family: 'Montserrat', sans-serif;
        font-size: 1rem;
        font-weight: 500;
        line-height: 1.3em;
      }
    }
  }
}

@media only screen and (max-width : 640px) {
  .gallery {
    width: 100%;

    &--item {
      height: 245px;
    }
  }
}