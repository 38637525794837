/* --- FORM CSA --- */
@import '../Forms/forms.scss';

.forms {
  &--content {
    &--send {
      
    }
  }

  &--form {
    max-width: 480px;
    &.csa {
      .btn-triangle {
        display: block;
      }
    }
  }
}