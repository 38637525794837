/* --- COLORS --- */
$black-900: #222;
$black-400: rgba(0, 0, 0, 0.5);
$black-350: #414042;
$black-300: #4E4E4E;
$black-200: #828282;
$black-150: #808080;
$black-100: #787878;
$black-50: #6F7279;

$blue-900: #00315E;
$blue-500: #016D9B;
$blue-400: #0173A4;
$blue-350: #009BDB;
$blue-300: #0180B6;
$blue-250: #5EAFD1;
$blue-200: #0AABE4;
$blue-150: #81DEFF;
$blue-100: #49C8F5;
$blue-70: #4AC8F5;
$blue-50: #E4F5FD;
$blue-10: #C7EAFB;
$blue-f: #4267B2;

$green-900: #005660;
$green-800: #24647A;
$green-600: #1495A5;
$green-200: #00ABC1;
$green-100: #06CBCB;

$red-900: #F20022;
$red-700: #EC2227;
$red-600: #EE3124;

$purple-900: #F50084;

$yellow-800: #ECD351;
$yellow-700: #F2D642;
$yellow-600: #F6DD5C;
$yellow-300: #FEE356;

$white-900: #FFF;
$white-600:#E0E0E0;
$white-500: #F2F2F2;
$white-400: #F5F5F5;
$white-300: #E6E7E7;