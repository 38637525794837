/* --- SEARCH --- */
@import './../../styles/settings/settings.scss';
@import './../../styles/tools/mixins.scss';

.search {
  &-page {
    @include box-display;
    float: left;
    padding: 0;
    position: relative;
    width: 100%;

    &--info,
    &--title {
      @include box-display;
      float: left;
      position: relative;
      width: 100%;
    }

    &--info {
      @include font($font-default, 14px, 400, $black-400);
      margin: 30px 0;
      text-align: center;
    }

    &--title {
      @include font($font-default, 72px, 300, $blue-900);
      line-height: 1em;
      margin: 100px 0 0;
      text-align: center;
    }

    .circulars {
      &--item {
        .info {
          cursor: pointer;
          padding: 20px 30px;
          width: 100%;

          .title {
            margin: 0;
          }
        }
      }

      &--list {
        margin: 30px 0;
        max-width: 490px;
        text-align: left;
        width: 100%;
      }

      .wrapper {
        text-align: center;
      }
    }
  }
}

/* --- QUERIES --- */
/* Medium Devices, Desktops */
@media only screen and (max-width: 1024px) {
  .search {
    &-page {
      &--title {
        @include rem('font-size', 40px);
        margin: 70px 0 0;
      }
    }
  }
}