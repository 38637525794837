/* --- CALENDAR MONTH HEADER --- */
@import './../../../../styles/settings/settings.scss';
@import './../../../../styles/tools/mixins.scss';

.calendar--month--header {
  @include box-display;
  float: left;
  margin: 0 0 15px;
  position: relative;
  width: 100%;

  .day,
  .week {
    @include box-display;
    float: left;
    position: relative;
    width: 100%;
  }

  .day {
    @include font($font-default, 14px, 600, $blue-400);
    text-align: center;
    width: calc(100% / 7);

    &[data-weekend="true"] {
      color: $black-200;
    }
  }
}