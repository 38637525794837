/* --- CALENDAR CATEGORIES --- */
@import './../../../styles/settings/settings.scss';
@import './../../../styles/tools/mixins.scss';

.calendar--categories {
  @include box-display;
  float: left;
  position: relative;
  width: 100%;

  &--item {
    @include box-display;
    float: left;
    padding: 0;
    position: relative;
  }

  &--item {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    flex-flow: row wrap;
    margin: 0 20px 0 0;
    padding: 10px 0;

    .text {
      @include box-display;
      @include font($font-default, 16px, 300, $blue-900);
      float: left;
      line-height: 1em;
      margin: 0;
      position: relative;
    }

    .block {
      @include box-display;
      border-radius: 4px;
      height: 7px;
      float: left;
      margin: 0 7px 0 0;
      position: relative;
      top: 1px;
      width: 20px;
    }
  }

  .wrapper {
    padding: 0 20px;
  }
}