/* --- NEWS --- */
@import './../../styles/settings/settings.scss';
@import './../../styles/tools/mixins.scss';

.news {
  @include box-display;
  float: left;
  position: relative;
  max-width: 100%;
  width: 100%;

  .filter {
    &--list {
      max-width: 1060px;
      width: fit-content;
      padding-left: 0;
      margin: 0 auto 60px;
      list-style: none;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
    }

    &--item {
      background-color: #F2F2F2;
      padding: 10px;
      font-family: 'Montserrat', sans-serif;
      font-size: 18px;
      color: #00315E;
      position: relative;
      cursor: pointer;

      &.active {
        background-color: #E4F5FD;
        padding-right: 30px;
        
        &::after {
          content: "";
          background: url("/images/icons/check-active-item.svg");
          width: 11px;
          height: 11px;
          position: absolute;
          top: 50%;
          right: 10px;
          transform: translate3d(0, -50%, 0);
        }
      }
    }
  }

  &--list {
    list-style: none;
    margin: 0 auto;
    max-width: $page-width;
    padding: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 17px;
  }

  &--loader {
    @include box-display;
    @include font($font-default, 14px, 400, $blue-900);
    float: left;
    position: relative;
    text-align: center;
    width: 100%;
  }
}

/* --- QUERIES --- */
/* Medium Devices, Desktops */
@media only screen and (max-width : 1024px) {
  .news {
    padding: 0 20px;
  }
}