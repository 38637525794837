.Bookshelf {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  &--shelf {
    position: relative;
    margin-bottom: 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &--shelf::after {
    background: url('/images/capelavirtual/wall-bookshelf.png');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: bottom center;
    display: block;
    content: '';
    width: 665px;
    height: 210px;
    position: absolute;
    top: 30px;
    left: 50%;
    transform: translateX(-50%);
  }

  & .pagination {
    margin-left: 10px;
    margin-right: 10px;
    cursor: pointer;
    z-index: 10;
  }
}

@media screen and (max-width: 480px) {
  .Bookshelf {
    &--shelf::after {
      width: 100%;
      top: -65px;
    }
  }
}