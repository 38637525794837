.Book {
  $book-height: 155px;

  display: inline-block;
  z-index: 10;
  position: relative;
  height: $book-height;
  box-shadow: 0px 10px 5px #3b3b3bbb;
  height: $book-height;
  width: 120px;
  margin-left: 50px;
  margin-right: 50px;

  &--thumbnail {
    width: 100%;
    height: 100%;
  }
}

@media screen and (max-width : 480px) {
  .Book {
    margin-right: 5px;
    margin-left: 5px;

    height: 100px;
    width: 75px;
  }
}