.Prayers {
  color: white;
  max-width: 700px;
  margin: 0 auto;

  & h1, h2, h3, p {
    margin: 0;
  }

  .prayers-list {
    list-style-type: none;
    margin: 0;
    padding: 0;

    & li {
      display: block;
      background-color: rgba(0, 0, 0, 0.6);
      transition: .5s all ease-in-out;
      padding: 30px;

      &:not(:first-child) {
        margin: 10px 0;
      }

      & h3, p {
        text-align: left;
      }

      & p {
        font-size: .9em;
      }

      &:hover {
        background-color: rgba(0, 0, 0, 0.8);
        cursor: pointer;
      }
    }

    &--content {
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-left: 1ch;

      & p {
        width: auto;
        display: inline;

        &:not(:first-child) {
          padding-left: 0.5ch;
        }
      }
    }
  }
  
  .PrayerShow {
    margin-top: 12px;
    background-color: rgba(0, 0, 0, 0.6);
    padding: 8px;
    overflow-y: auto;
    position: relative;

    & p {
      margin: 24px 0;
    }

    h3 {
      margin-top: 24px;
    }

    &--close {
      position: absolute;
      right: 0;
      background: none;
      border: none;
      color: white;
      text-decoration: underline;
      cursor: pointer;
      margin-right: 8px;
    }
  }
}