/* --- GRID --- */
@import '../settings/colors.scss';

$page-width: 1380px;

html {
  box-sizing: border-box;
  overflow: hidden;
  overflow-y: auto;

  ::-webkit-scrollbar-track {
    background-color: #F5F5F5;
  }

  ::-webkit-scrollbar {
    background-color: #F5F5F5;
    width: 12px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: $blue-400;
    width: 12px;
  }

  &.no-scroll {
    overflow-y: hidden;
  }
}

body {
  box-sizing: border-box;
  display: inline-block;
  float: left;
  margin: 0;
  min-height: 100vh;
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 100%;
}

.wrapper {
  box-sizing: border-box;
  display: inline-block;
  max-width: $page-width;
  text-align: left;
  width: 100%;
  vertical-align: top;
}